import { nationalityOptions, countriesOptions } from '@/utils/countries'
import { titleOptions, genderOptions } from '@/utils/constants'

export default {
  name: 'personalInfoForm',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      title: 'Profile information',
      fields: [
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            isInline: false,
            name: 'title',
            veeAs: ' ',
            label: 'Title',
            placeholder: 'Select a title',
            rules: 'required',
            columns: 2,
            options: titleOptions,
            selected: ''
          },
          {
            choice: 'single',
            field: 'select',
            type: '',
            isInline: false,
            name: 'gender',
            veeAs: ' ',
            label: 'Gender (optional)',
            placeholder: 'Not Specified',
            rules: '',
            options: genderOptions,
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'firstName',
            veeAs: 'first name',
            label: 'First name',
            placeholder: '',
            rules: 'required',
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'lastName',
            veeAs: 'last name',
            label: 'Last name',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'datepicker-dropdown',
            type: '',
            name: 'doB',
            veeAs: 'day of birth',
            label: 'Date of birth',
            placeholder: 'DD-MM-YYYY',
            icon: {
              name: 'today',
              position: 'right'
            },
            rules: 'required',
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'position',
            label: 'Occupation',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'tel',
            name: 'contactNumber',
            veeAs: 'contact number',
            label: 'Contact number',
            placeholder: '+44 (ХХХ) XXXXXXX',
            icon: {
              name: 'phone',
              position: 'right'
            },
            rules: 'required',
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'nationality',
            veeAs: 'nationality',
            label: 'What is your nationality?',
            placeholder: 'Select a nationality',
            rules: 'required',
            options: nationalityOptions,
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'isDualNationalityHolder',
            veeAs: ' ',
            label: 'Are you a dual nationality holder?',
            placeholder: '',
            rules: 'required',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'otherDualNationality',
            veeAs: 'other nationality',
            label: 'What is your other nationality?',
            placeholder: 'Select a nationality',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'isDualNationalityHolder',
                values: [true]
              }
            ],
            options: nationalityOptions,
            selected: ''
          },
          {
            field: 'dummy',
            dependsOn: [
              {
                fieldName: 'isDualNationalityHolder',
                values: [true]
              }
            ]
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'countryOfResidence',
            veeAs: 'country of residence',
            label: 'What is your country of residence?',
            placeholder: 'Select country',
            rules: 'required',
            options: countriesOptions,
            selected: ''
          },
          {
            field: 'dummy'
          }
        ]
      ]
    }
  ]
}
