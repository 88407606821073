<template>
  <div class="ui stackable sixteen column padded grid">
    <main class="twelve wide column">
      <div class="ui container">
        <h2 class="ui title m-b-2 h2">Your personal information</h2>
        <div class="ui m-b-3">
          <DynamicForm class="financial-information-info-form" v-model="personalInfoForm" :submit-promise="submitPromise" submit-text="Next" @df-submit="submitPersonalInfo">
            <template #beforeSubmit>
              <router-link class="ui secondary button circular m-t-1 m-r-1" :to="{ name: 'dashboard' }">
                Back
              </router-link>
            </template>
          </DynamicForm>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import deepcopy from 'deepcopy'
import { mapGetters, mapActions } from 'vuex'
import { authGetters, userRoutine } from '@/store/modules/auth/routines'
import DynamicForm from '@/components/forms/DynamicForm'
import personalInfoForm from '@/features/profile/personalInfo/personalInfo.form'
import { updateUser } from '@/api/user'
import { prefillFormData } from '@/utils/formHelpers'

export default {
  name: 'JourneyPersonalInfo',
  components: {
    DynamicForm
  },
  data() {
    return {
      personalInfoForm: deepcopy(personalInfoForm),
      submitPromise: null
    }
  },
  computed: {
    ...mapGetters({
      user: authGetters.USER
    })
  },
  methods: {
    ...mapActions({
      requestUser: userRoutine.TRIGGER
    }),
    updatePersonalInfoFormData() {
      return this.requestUser().then(res => {
        prefillFormData(this.personalInfoForm, res.data)
        return res.data
      })
    },
    submitPersonalInfo(formData) {
      const data = {
        ...formData.data,
        userId: this.user.userId
      }
      this.submitPromise = updateUser(data)
        .then(this.updatePersonalInfoFormData)
        .then(() => {
          // @TODO Ask about this personal info page
          this.$router.push({ name: 'journey-business' })
        })
      return this.submitPromise
    }
  },
  created() {
    prefillFormData(this.personalInfoForm, this.user)
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';

.ui.stackable.grid {
  height: 100%;
  background-color: $color-white;
  .container {
    padding: 5rem;
    &.help {
      margin: 0 auto;
      padding: 1rem;
      height: 100%;
      display: flex;
      .content {
        width: 200px;
        margin-top: 15rem;
      }
    }
    .form {
      .ui.button {
        margin-top: 1rem;
        margin-right: 1rem;
        min-width: 96px;
      }
    }
  }
}
</style>
